import { resetTokenApi } from "../../apis/auth.api"
import MessageDilog from "../../components/MessageDilog"

import { accessToken, refreshToken } from "../../utils/helper"
import { openModal } from "./modalAction"
import { signOutAction } from "./userReducerAction"


export const callApiAction = (asyncFun, onSuccess = () => { }, onError = () => { }) => {

    return async (dispatch, getState) => {

        const response = await asyncFun()

        if (response.status === 1) {
           await onSuccess(response.data)
        } else {
            
            if (response.code === 400) {
                onError(Array.isArray(response.data) && response.data[0].msg,response.data)
            } else if (response.code === 401) {
                const resetFunResponse = await resetTokenApi()
                if (resetFunResponse.status === 1) {
                    
                    accessToken.set(resetFunResponse.data.accessToken)
                    refreshToken.set(resetFunResponse.data.refreshToken)

                    dispatch(callApiAction(asyncFun, onSuccess, onError))

                } else if (resetFunResponse.code === 401) {
                    dispatch(signOutAction())
                }
            } else {
                dispatch(openModal(<MessageDilog title="Error" message={response.message || "OOPS! Something went wrong"}/>,'sm'))
                onError(response.message || "OOPS! Something went wrong")
            }
        }
    }


}