import { useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { openModal } from "../../store/actions/modalAction"
import { loggedInUser } from "../../utils/helper"
import PasswordResetController from "./PasswordResetController"
import ProfileUi from "./ProfileUi"

const ProfileController = () => {
    const user = useSelector(state => state.user)
    const [state, setState] = useState(user.data)
    const dispatch = useDispatch()
    const onUpdateValue = () => {

    }
    const onUpdatePasswordCick = () => {
        dispatch(openModal(<PasswordResetController />), "md")
    }
    const fields = useMemo(() => [
        {
            title: "Name",
            key: 'name',
            disabled: true,
            editable: true,
            type: 'text'
        },
        {
            title: "User Code",
            key: 'usercode',
            disabled: true,
            editable: true,

        },
        {
            title: "Company Name",
            key: 'companyName',
            disabled: true,
            editable: true,

        },
        {
            title: "Email",
            key: 'email',
            disabled: true,
            editable: true,

        },
        {
            title: "Phone No.",
            key: 'phoneNo',
            disabled: true,
            editable: true,

        },
        {
            title: "Role",
            key: 'roleName',
            disabled: true,
            editable: true,

        },
        {
            title: "Territory",
            key: 'territory',
            disabled: true,
            editable: true,

        }, {
            title: "GST No.",
            key: 'gstNo',
            disabled: true,
            editable: true,

        },

    ], [])
    return <ProfileUi onUpdatePasswordCick={onUpdatePasswordCick} setState={setState} fields={fields} state={state} onUpdateValue={onUpdateValue} />
}
export default ProfileController