import { actions } from "../../utils/constants"


export const openModal = (component, size = 'md') => {
    
    return { type: actions.OPEN_MODAL, component, size }


}
export const closeModal = () => {
    return { type: actions.CLOSE_MODAL }
}