import { useState } from "react"
import CustomInput from "../../../components/inputs/CustomInput"
import { Box } from "@mui/material"
import TicketDetailsController from "../TicketDetailsController"
import SubmitButton from "../../../components/button/SubmitButton"
import { REPLACEMENT_TICKETS_STATUS, REPLACEMENT_TICKETS_STATUS_OBJ } from "../../../utils/constants"

const CustomerReplacementFindTicketUi = ({  fields, setFields, setLoading, loading, setStep, ticketData, setTicketData, onOtpSend }) => {
    const [ticketNo, setTicketNo] = useState('')

    return <>
        {!(ticketData && ticketData._id) && <Box component="form" onSubmit={(e) => { e.preventDefault(); setFields({ ...fields, ticketNo }) }} >
            {<CustomInput
                mt={2}
                fullWidth={true}
                disabled={loading}
                placeholder={"Enter Ticket No"}
                value={ticketNo}
                onChange={(e) => { setTicketNo(e.target.value) }} />}
            <Box mt={2}></Box>
            <SubmitButton type="submit" title="Search" />
        </Box>}
        {
            fields.ticketNo != '' && <>

                <TicketDetailsController
                    onlyTicket
                    onStart={() => { setLoading(true) }}
                    onError={() => { setLoading(false) }}
                    onSuccess={(response) => { setLoading(false); setTicketData(response) }}
                    ticketNo={fields.ticketNo}

                />
            </>
        }
        {
            (ticketData && ticketData._id && ticketData.status == REPLACEMENT_TICKETS_STATUS_OBJ.approved_and_pending) &&
            <Box sx={{ width: "100%" }} p={4}><SubmitButton onClick={onOtpSend} loading={loading} title="Send OTP For Replacement"></SubmitButton>
            </Box>
        }
    </>
}
export default CustomerReplacementFindTicketUi