import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'
import { getLeadStatusApi, getLeadTypeApi, getLeadUsersApi } from "../../apis/lead.api"
import { getUserRolesApi } from "../../apis/user.api"
import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"

const LeadCreateUi = ({ fields, setFields, loading, onSubmit }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h3">{'Raise Lead'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>

                <CustomInput
                    disabled={loading}
                    value={fields.leadTitle}
                    onChange={(e) => setFields({ ...fields, err: '', leadTitle: e.target.value })}
                    type="text"
                    label={"Lead Title*"}

                />

                <CustomInput
                    disabled={loading}
                    value={fields.personName}
                    onChange={(e) => setFields({ ...fields, err: '', personName: e.target.value })}
                    type="personName"
                    label={"Person Name*"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.contactNo}
                    onChange={(e) => setFields({ ...fields, err: '', contactNo: e.target.value })}
                    type="contactNo"
                    label={"Contact No.*"}

                />



                <AsyncDropDown

                    lazyFun={getLeadTypeApi}
                    label="Lead Type*"
                    onChange={async (changedVal) => { setFields({ ...fields, err: '', type: changedVal }) }}
                    titleKey={'name'}
                    valueKey={"_id"}


                />
                {/* <AsyncDropDown

                    lazyFun={getLeadStatusApi}
                    label="Status"
                    onChange={async (changedVal) => { setFields({ ...fields, err: '', status: changedVal }) }}
                    titleKey={'name'}
                    valueKey={"_id"}


                /> */}



                <AsyncDropDown

                    lazyFun={getLeadUsersApi}
                    label="Assigned To*"
                    
                    onChange={async (changedVal) => { setFields({ ...fields, err: '', to: changedVal }) }}
                    titleKey={'name'}
                    valueKey={"_id"}


                />

                <CustomInput
                    multiline
                    rows={4}
                    disabled={loading}
                    value={fields.message}
                    onChange={(e) => setFields({ ...fields, err: '', message: e.target.value })}
                    type="text"
                    label={"Message*"}

                />

                <CustomInput
                    multiline
                    rows={4}
                    disabled={loading}
                    value={fields.address}
                    onChange={(e) => setFields({ ...fields, err: '', address: e.target.value })}
                    type="text"
                    label={"Address"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.companyName}
                    onChange={(e) => setFields({ ...fields, err: '', companyName: e.target.value })}
                    type="companyName"
                    label={"Company Name"}

                />
                <CustomInput
                    disabled={loading}
                    value={fields.email}
                    onChange={(e) => setFields({ ...fields, err: '', email: e.target.value })}
                    type="email"
                    label={"Email"}

                />











            </DialogContent>
            <DialogActions   >
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton type="submit" loading={loading} title="Create" ></SubmitButton>
            </DialogActions>
        </Box>
    </>
}
export default LeadCreateUi