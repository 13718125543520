import { Chip, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'

import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"
import { fetchInventoryList } from "../../apis/inventory.api"
import { REPLACEMENT_TICKETS_STATUS } from "../../utils/constants"
import { dateConverter } from "../../utils/helper"

const TicketDetailsUi = ({ loading, data, onlyTicket }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        {!onlyTicket && <DialogTitle variant="h3">{'Ticket Details'}

        </DialogTitle>}
        <DialogContent>
            {loading && <Skeleton width="100%" height={"100px"}></Skeleton>}
            {!loading && data && data._id ? <>
                <Box mt={2} mb={2} elevation={1} sx={{ width: "100%", background: "#f2f2f2", borderRadius: 2 }} p={2}>
                    <Box mb={3} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h5">{data.ticketNo}</Typography>
                        <Chip size="small" label={REPLACEMENT_TICKETS_STATUS[data.status]} color={data.status == 'rejected' ? "error" : data.status == "delivery_confirmed" ? "success" : "warning"} />
                    </Box>
                    <Grid container spacing={1}>
                        <Grid item xs={12} md={2}>
                            <Typography fontWeight="bold" variant="body2">Raised By : </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Typography variant="body2">{data.by ? data.by.name : data.customer.name}</Typography>
                        </Grid>

                        <Grid item xs={12} md={2}>
                            <Typography variant="body2">Raised At : </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>{dateConverter({ type: "DD_MM_YYYY", value: data.createdAt })}</Grid>
                        <Grid item xs={12} md={2}>
                            <Typography fontWeight={"bold"} variant="body2">Type : </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography variant="body2" textTransform="capitalize">{data.type} </Typography>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderStyle: "dashed", mt: 2, mb: 2, borderWidth: 1 }} mb={2} mt={2} />
                    <Typography variant="h6">Devices :</Typography>
                    <Box sx={{ display: "flex" }}>
                        {data.returnedDevice && data.returnedDevice.map((boxId) => <Box key={boxId} mr={1} mt={1}><Chip color="secondary" label={boxId} /></Box>)}
                    </Box>
                    <Typography variant="h6" mt={2}>Remarks :</Typography>
                    <Typography variant="body2">{data.remarks}</Typography>

                    {data.replacedDevice && data.replacedDevice.length > 0 && <>
                        <Divider sx={{ borderStyle: "dashed", mt: 2, mb: 2, borderWidth: 1 }} mb={2} mt={2} />
                        <Typography variant="h6">Replaced Devices</Typography>
                        <Box sx={{ display: "flex" }}>
                            {data.replacedDevice && data.replacedDevice.map((boxId) => <Box key={boxId} mr={1} mt={1}><Chip color="secondary" label={boxId} /></Box>)}
                        </Box>
                    </>}
                </Box>
            </> : <Typography align="center" variant="h2"> No Ticket Found</Typography>}
        </DialogContent>
        {!onlyTicket && <DialogActions   >
            <Box sx={{ display: "flex", flex: 1 }}></Box>
            <Box><SubmitButton onClick={handleClose} title={'close'}></SubmitButton></Box>
        </DialogActions>}

    </>
}
export default TicketDetailsUi