import { useEffect, useState } from "react"
import UserPageUi from "./UserPageUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { getUserApi, getUserCountApi } from "../../apis/user.api"
import { useLocation, useParams } from "react-router-dom"
import { openModal } from "../../store/actions/modalAction"
import UserCreateController from "./UserCreateController"
const UserController = () => {

    const [list, setList] = useState({})
    const [roles, setRoles] = useState([

    ])

    const [loading, setLoading] = useState(false)
    const [listLoading, setListLoading] = useState(false)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        role: ''

    })

    const fetchList = (usercode) => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await getUserApi({ ...filters, usercode }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const fetchCounts = (usercode) => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await getUserCountApi({...filters,usercode}),
            (response) => {
                let total = 0

                if (response && Array.isArray(response)) {
                    for (let item of response) {
                        total = total + item.count

                    }

                    response.unshift({
                        count: total,
                        role: '',
                        roleName: "All"
                    })

                    setRoles(response)
                }

                setLoading(false)
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onCreateBtnClick = () => {
        dispatch(openModal(<UserCreateController callBack={async () => { await Promise.all([fetchList(), fetchCounts()]) }} />, "sm"))
    }

    useEffect(() => {
        fetchList(params.usercode)
        console.log('url changed')
    }, [filters, params.usercode])

    useEffect(() => {
        fetchCounts(params.usercode)
    }, [params.usercode])

    return (
        <>
            <UserPageUi
                onCreateBtnClick={onCreateBtnClick}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                loading={loading}
                list={list}
                roles={roles} />

        </>
    )
}
export default UserController