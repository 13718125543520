import { memo, useMemo, useState } from "react"
import useValidate from "../../store/hooks/useValidator"
import UserCreateUI from "./UserCreateUI"
import { useDispatch } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { addUserApi } from "../../apis/user.api"
import { closeModal } from "../../store/actions/modalAction"
import { useParams } from "react-router-dom"

const UserCreateController = ({ callBack }) => {
    const validate = useValidate()
    const dispatch = useDispatch()
    const params = useParams()

    const [loading, setLoading] = useState(false)

    const [fields, setFields] = useState({
        err: '',
        name: '',
        email: '',
        password: '',
        role: null,
        phoneNo: '',
        companyName: '',
        gstNo: '',
        territory: "",
        usercode: params.usercode

    })


    const validationSchema = useMemo(() => ([
        {
            required: true,
            value: fields.email,
            field: 'Email',
            isEmail: true,
        },
        {
            required: true,
            value: fields.name,
            field: 'Name',
        },
        {
            required: true,
            value: fields.password,
            field: 'Password',
        },
        {
            required: true,
            value: fields.phoneNo,
            field: 'Phone no.',
        },
        {
            required: true,
            value: fields.role,
            field: 'Role',
        }
    ]), [fields])


    const onSubmit = async (e) => {
        e.preventDefault()

        const validationResponse = validate(validationSchema)

        if (validationResponse === true) {


            setLoading(true)
            dispatch(
                callApiAction(
                    async () => await addUserApi(fields),
                    async (response) => {

                        await callBack()
                        setLoading(false)
                        dispatch(closeModal())
                    },
                    (err) => {
                        setLoading(false)
                        setFields({ ...fields, err })
                    }
                )
            )
        } else {
            setFields({ ...fields, 'err': validationResponse })
        }
    }

    
    return <UserCreateUI loading={loading} fields={fields} onSubmit={onSubmit} setFields={setFields} />
}
export default memo(UserCreateController)