import { FormControl, MenuItem, Select } from "@mui/material"

const DropDown = ({dropDownData,dropDownProps})=>{
 return   <FormControl variant="standard"  fullWidth={true} >
    <Select
        fullWidth={true}
        // labelId="demo-simple-select-standard-label"
        // id="demo-simple-select-standard"
        label={null}
        variant="outlined"
        size="small"
        // label="s"
        // sx={{ ":before": { display: "none" } }}
        {...dropDownProps}
    >

        {
            dropDownData.map((item) => {
                return <MenuItem key={item.id} value={item.id}>
                    {item.label}
                </MenuItem>
            })
        }
        <MenuItem value={' '}>
            Lifetime
        </MenuItem>
    </Select>
</FormControl>
}
export default DropDown