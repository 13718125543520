import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { revertBoxApi, splitConvertedBoxApi } from "../../apis/inventory.api"
import { callApiAction } from "../../store/actions/commonAction"
import { closeModal, openModal } from "../../store/actions/modalAction"
import { inventoryStatus, permissions } from "../../utils/constants"
import MessageDilog from "../MessageDilog"
import SubmitButton from "./SubmitButton"

const SplitConvertedBoxesButton = ({ boxId, convertedProducts, status, callBack = () => { } }) => {

    const [loading, setLoading] = useState()

    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const onSubmit = () => {

        setLoading(true)
        dispatch(
            callApiAction(
                async () => await splitConvertedBoxApi({ boxId }),
                async (response) => {

                    await callBack()
                    setLoading(false)
                    dispatch(closeModal())
                },
                (err) => {
                    setLoading(false)
                    alert(err)
                }
            )
        )
    }
    const onSplitButtonclick = () => {
        dispatch(openModal(<MessageDilog title="Alert !" onSubmit={onSubmit} message={`Are you sure to split device ${boxId}?`} />, "sm"))
    }
    
    if (user.permissions.includes(permissions.CRM_MANAGERS) && status == inventoryStatus.NOT_ALLOCATED && convertedProducts && Array.isArray(convertedProducts) && convertedProducts.length > 0)
        return <SubmitButton loading={loading} title={"Split"} onClick={onSplitButtonclick} />
    else
        return <></>
}
export default SplitConvertedBoxesButton