import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInvoiceHtml, fetchInvoices } from "../../../apis/inventory.api"
import InvoicePageUi from "./InvoicePageUi"
import jsPDF from 'jspdf';
import html2canvas from "html2canvas"
import { openModal } from "../../../store/actions/modalAction"
import InvoicePreview from "./InvoicePreview"

const InvoicesController = () => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: ''

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchInvoices({ ...filters }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const onDownloadInvoice = useCallback(async (invoiceId, name) => {
        dispatch(openModal(<InvoicePreview invoiceId={invoiceId} name={name} />,'md'))
    }, [])


    useEffect(() => {
        fetchList()
    }, [filters])


    return (
        <>
            <InvoicePageUi
                onDownloadInvoice={onDownloadInvoice}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
            />

        </>
    )
}
export default InvoicesController