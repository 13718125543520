import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInventoryList, fetchSoldList, fetchStockCountByProductCategory } from "../../../apis/inventory.api"
import { getDateFiltersTime } from "../../../utils/helper"
import StockPageUi from "./StockPageUi"
import { openModal } from "../../../store/actions/modalAction"
import TransferBoxesController from "../common/TransferBoxesController"
import SellBoxesController from "../common/SellBoxesController"
import ImportDevicesController from "../importDevices/ImportDevicesController"

const StockController = ({status}) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)

    const [counts, setCount] = useState([])
    const [countLoading, setCountLoading] = useState(false)

    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        productCategory: params.productCategoryId,
        status:status??"sellable"

    })
    const fetchList = () => {

        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchInventoryList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {

                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }
    const fetchStockCount = () => {
        setCountLoading(true)

        dispatch(callApiAction(
            async () => await fetchStockCountByProductCategory({ ...filters }),
            (response) => {
                const temp = [...response]
                if (response && Array.isArray(response)) {
                    let total = temp.reduce((totalCount, item) => (totalCount + parseInt(item.count)), 0)
                    temp.unshift({
                        _id: '',
                        name: "All",
                        count: total
                    })
                }
                setCount(temp)
                setCountLoading(false)
            },
            (err) => {
                setCountLoading(false)
            }
        ))
    }
    const fetchStockDetailsAndData = () => {
        // fetchStockCount()
        fetchList()

    }

    const onMassSellBtnClick = useCallback((defaultData = [], size = "md") => {
        dispatch(openModal(<SellBoxesController defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Sell" />, size))
    }, [])

    const onTransferBtnClick = useCallback((defaultData = [], size = "md") => {
        dispatch(openModal(<TransferBoxesController defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Transfer" />, size))
    }, [])
    const onFreeTransferBtnClick = useCallback((defaultData = [], size = "md") => {
        dispatch(openModal(<SellBoxesController isFree defaultData={defaultData} callBack={() => { fetchStockDetailsAndData() }} title="Free Sale" />, size))
    }, [])

    const onImportBtnClick = useCallback(() => {
        dispatch(openModal(<ImportDevicesController callBack={() => { fetchStockDetailsAndData() }} />, 'lg'))
    }, [])

    useEffect(() => {
        fetchList()
    }, [filters, inventoryCount.filters])

    useEffect(() => {
        // fetchStockCount()
    }, [])
    return (
        <>
            <StockPageUi
            onFreeTransferBtnClick={onFreeTransferBtnClick}
                countLoading={countLoading}
                counts={counts}
                onImportBtnClick={onImportBtnClick}
                onTransferBtnClick={onTransferBtnClick}
                onMassSellBtnClick={onMassSellBtnClick}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                fetchList={(productCategory) => {
                    setFilters({ ...filters, productCategory })
                }}
                list={list}
            />

        </>
    )
}
export default StockController