import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchSoldInvoiceList, fetchSoldList, payInvoiceApi } from "../../../apis/inventory.api"
import { getDateFiltersTime } from "../../../utils/helper"
import SoldPageUi from "./SoldPageUi"
import { invoicePaymentStatus, salesTypes } from "../../../utils/constants"
import InvoicePreview from "../invoices/InvoicePreview"
import { closeModal, openModal } from "../../../store/actions/modalAction"
import MessageDilog from "../../../components/MessageDilog"
import { fetchInventoryCountAction } from "../../../store/actions/inventoryCountAction"
import SoldInvoicesUi from "./SoldInvoicesUi"
const SoldInvoiceCOntroller = ({ paymentStatus }) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)
    const inventoryCount = useSelector(state => state.inventoryCount)

    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        salesType: '',
        paymentStatus: paymentStatus,
        productCategory: params.productCategoryId

    })

    const fetchList = () => {
        setListLoading(true)
        dispatch(callApiAction(
            async () => await fetchSoldInvoiceList({ ...filters, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime) }),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }

    const onDownloadInvoice = useCallback(async (invoiceId, name) => {
        dispatch(openModal(<InvoicePreview invoiceId={invoiceId} name={name} />, 'md'))
    }, [])


    const payInvoice = useCallback((type, boxId, invoiceId) => {
        return () => {
            
            dispatch(callApiAction(
                async () => await payInvoiceApi({ usercode: params.usercode, type, boxId, invoiceId }),
                (response) => {
                    dispatch(fetchInventoryCountAction({ usercode: params.usercode, productCategory: params.productCategoryId, ...inventoryCount.filters, ...getDateFiltersTime(inventoryCount.filters.dateTime)}, () => { }, () => { }))
                    fetchList()
                    dispatch(closeModal())
                },
                (err) => {
                    alert("something wents wrong")
                }
            ))
        }
    },[dispatch,inventoryCount,params])

    const onPaymentbuttonCLick = useCallback((type, boxId, invoiceId) => {
        dispatch(openModal(<MessageDilog title="Alert!" message={"Are you sure to mark it paid?"} onSubmit={payInvoice(type, boxId, invoiceId)} />,"xs"))
    }, [dispatch,inventoryCount,params])

    useEffect(() => {
        fetchList()
    }, [filters, inventoryCount.filters])


    return (
        <>
            <SoldInvoicesUi
                onPaymentbuttonCLick={onPaymentbuttonCLick}
                onDownloadInvoice={onDownloadInvoice}
                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
paymentStatus={paymentStatus}
                list={list}
            />

        </>
    )
}
export default SoldInvoiceCOntroller