import { Button, ButtonGroup, Chip, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { dateConverter } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import { invoicePaymentStatus, salesTypes } from "../../../utils/constants"
import { Done, Download } from "@mui/icons-material"
import PriceCheckIcon from '@mui/icons-material/PriceCheck';

const SoldPageUi = ({ listLoading, filters, setFilters, list, onDownloadInvoice, onPaymentbuttonCLick }) => {
    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>

            <Box pt={4}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Sold Items</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <SearchBar title="Search by Box Id & Customer Name" placeholder={"Search by Box Id & Customer Name"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                        </Box>
                    </Box>
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "sflex-end", alignItems: "center" }}>
                        <ButtonGroup variant="outlined" sx={{ margin: 1 }}>
                            <Button color="primary" disableElevation variant={filters.salesType === '' ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: '' })} >All</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2C ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2C })} >B2C</Button>
                            <Button color="primary" disableElevation variant={filters.salesType === salesTypes.B2B ? "contained" : 'outlined'} onClick={() => setFilters({ ...filters, salesType: salesTypes.B2B })} >B2B</Button>
                        </ButtonGroup>
                    </Box>
                    <TableContainer >


                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>
                                    <TableCell>Box Id</TableCell>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell >Customer Phone</TableCell>
                                    <TableCell >Customer Email</TableCell>
                                    <TableCell >Customer Address</TableCell>
                                    <TableCell >Amount</TableCell>
                                    <TableCell >Sale Date</TableCell>
                                    {/* <TableCell >Sold At</TableCell> */}
                                    {/* <TableCell >Download Invoice</TableCell> */}
                                    {/* <TableCell >Payment Terms</TableCell> */}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={9} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    <TableRow key={row.boxId}>
                                        <TableCell><Link to={`/dashboard/${params.usercode}/device/${row.boxId}`} > {row.boxId}</Link></TableCell>
                                        <TableCell >{row.customer && row.customer.cname}</TableCell>
                                        <TableCell >{row.customer && row.customer.cphone}</TableCell>
                                        <TableCell >{row.customer && row.customer.cemail}</TableCell>
                                        <TableCell >{row.customer && row.customer.caddress}</TableCell>
                                        <TableCell >{row.amount}</TableCell>
                                        <TableCell >{row.date && dateConverter({ value: row.date })}</TableCell>
                                        {/* <TableCell   >{row.customer && row.customer.soldWith}</TableCell> */}
                                        {/* <TableCell>

                                            <Tooltip title="download invoice">

                                                <IconButton
                                                    aria-label="download"
                                                    size="small"

                                                >
                                                    <Download onClick={() => {
                                                        onDownloadInvoice(row.invoiceId, '')
                                                    }} />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell> */}


                                        {/* <TableCell>
                                            {row.paid && row.paid == invoicePaymentStatus.CREDIT ?
                                                <Button
                                                disableElevation
                                                    onClick={() => {
                                                        onPaymentbuttonCLick("single", row.boxId, row.invoiceId)
                                                    }}
                                                    variant="contained"
                                                    color="primary"
                                                    aria-label="download"
                                                    size="small"

                                                >
                                                    
                                                    Mark&nbsp;As&nbsp;Paid
                                                </Button>
                                                : <Chip label="PAID" icon={<Done color="light" />} size="small" color="success" />}
                                        </TableCell> */}
                                    </TableRow>
                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default SoldPageUi