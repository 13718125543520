import { Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import { dateConverter } from "../../../utils/helper"
import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"


const ChannelSoldPageUi = ({ listLoading, filters, setFilters, list }) => {
    const user = useSelector(state => state.user)
    return (
        <>

            <Box pt={4}>
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Channel Sales</Typography>
                        <Box pl={2} sx={{  display: "flex", justifyContent: "flex-end" }}>
                            <SearchBar placeholder={"Search by Box Id & Customer Name"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                        </Box>
                    </Box>
                    <TableContainer >


                        <Table aria-label="collapsible table">

                            <TableHead>
                            <TableRow>
                                    <TableCell>Box Id</TableCell>
                                    <TableCell>Customer Name</TableCell>
                                    <TableCell >Customer Phone</TableCell>
                                    
                                    
                                    <TableCell >Amount</TableCell>
                                    <TableCell >Sale Date</TableCell>
                                    <TableCell >Sold By</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={9}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={9}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={9}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={9} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    <TableRow key={row._id}>
                                    <TableCell>{row.boxId}</TableCell>
                                    <TableCell >{row.sold && row.sold.cname}</TableCell>
                                    <TableCell >{row.sold && row.sold.cphone}</TableCell>
                                    
                                    <TableCell >{row.sold && row.sold.amount}</TableCell>
                                    <TableCell >{row.sold && dateConverter({ value: row.sold.date })}</TableCell>
                                    <TableCell >{row.sold && row.sold.soldBy && row.sold.soldBy.name}</TableCell>
                                </TableRow>
                                ))}
                            </TableBody>



                        </Table>


                        {list &&  list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default ChannelSoldPageUi