import { Button, CircularProgress, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { memo, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { center } from "../../assets/css/theme/common"
import SearchBar from "../../components/inputs/SearchBar"

import { dateConverter } from "../../utils/helper"
import { openModal } from "../../store/actions/modalAction"
import TicketDetailsController from "./TicketDetailsController"
import { Visibility } from "@mui/icons-material"
import SubmitButton from "../../components/button/SubmitButton"
import { REPLACEMENT_TICKETS_STATUS_OBJ, permissions } from "../../utils/constants"
import { callApiAction } from "../../store/actions/commonAction"
import { updateReplacementTicketStatus } from "../../apis/replacement.api"
import ReplaceDeviceController from "./ReplaceDeviceController"

const StatusActionComponent = ({ ticketNo, status, by, devices,setFilters,filters }) => {
    // console.log(devices)
    const params = useParams()
    const { user } = useSelector(state => state)
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [currentStatus, setStatus] = useState(status)

    const updateStatus = (updatedStatus) => {
        setLoading(true)
        dispatch(callApiAction(
            async () => await updateReplacementTicketStatus({ ticketNo, status: updatedStatus ,usercode:params.usercode}),
            (response) => {

                setLoading(false)
                setStatus(updatedStatus)
                setFilters({...filters,key:Math.random()})
            },
            (err) => {
                setLoading(false)
            }
        ))
    }
    const onDeliveryButtonClick = () => {
        dispatch(openModal(<ReplaceDeviceController callBack={()=>{setFilters({...filters,key:Math.random()})}} ticketNo={ticketNo} status={REPLACEMENT_TICKETS_STATUS_OBJ.delivered} returnedDeviceLength={devices.length} />))
    }
    if(loading){
        return <CircularProgress />
    }
    if (currentStatus == REPLACEMENT_TICKETS_STATUS_OBJ.approved_and_pending && by != user.data._id) {
        return <Box sx={{ display: "flex" }}>
            <SubmitButton loading={loading} title="Replace Device" onClick={() => { onDeliveryButtonClick() }} />
        </Box>
    }

    if (currentStatus == REPLACEMENT_TICKETS_STATUS_OBJ.delivered && by == user.data._id) {
        return <Box sx={{ display: "flex" }}>
            <SubmitButton loading={loading} title="Confirm Delivery" onClick={() => { updateStatus(REPLACEMENT_TICKETS_STATUS_OBJ.delivery_confirmed) }} />
        </Box>
    }
    if (user.permissions.includes(permissions.REPLACEMENT_APPROVE) && currentStatus == REPLACEMENT_TICKETS_STATUS_OBJ.pending)
        return <Box sx={{ display: "flex" }}>
            <SubmitButton loading={loading} title="Approve" onClick={() => { updateStatus(REPLACEMENT_TICKETS_STATUS_OBJ.approved_and_pending) }} />
            <Box ml={1}></Box>
            <SubmitButton loading={loading} title="Reject" onClick={() => { updateStatus(REPLACEMENT_TICKETS_STATUS_OBJ.rejected) }} />
        </Box>


    return "NA"
}
const ListRow = memo(({ ticketNo, date, devices, status, type, by, setFilters,filters}) => {
    const dispatch = useDispatch()
    const onViewMoreClick = () => {
        dispatch(openModal(<TicketDetailsController ticketNo={ticketNo} />, "sm"))
    }
    return <TableRow >
        <TableCell > {ticketNo}</TableCell>
        <TableCell > {type}</TableCell>
        <TableCell > {devices.length}</TableCell>
        <TableCell > {date}</TableCell>
        <TableCell > {<StatusActionComponent filters={filters} setFilters={setFilters} ticketNo={ticketNo} by={by} status={status} devices={devices} />}</TableCell>
        <TableCell > {<IconButton onClick={onViewMoreClick} size="small" ><Visibility /></IconButton>}</TableCell>


    </TableRow>
})


const ListUi = ({ listLoading, filters, setFilters, list, onMassSellBtnClick, onFreeTransferBtnClick, onTransferBtnClick, onImportBtnClick, countLoading, counts, fetchList }) => {

    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>

            <Box p={4} pl={0} pr={0} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Replacement Tickets</Typography>
                        <Box pl={2} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column", flex: 1 }}>
                            <SearchBar placeholder={"Search by Ticket Id"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />


                        </Box>
                    </Box>

                    <TableContainer >


                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>
                                    <TableCell>Ticket Id</TableCell>
                                    <TableCell>Ticket Type</TableCell>
                                    <TableCell sx={{ minWidth: "100px" }}>Total Device</TableCell>
                                    <TableCell sx={{ minWidth: "140px" }}>Date</TableCell>
                                    <TableCell sx={{ minWidth: "140px" }}>Action</TableCell>
                                    <TableCell></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={5}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={5} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    <ListRow filters={filters} setFilters={setFilters} type={row.type} by={row.by} key={row._id} status={row.status} devices={row.returnedDevice} ticketNo={row.ticketNo} date={dateConverter({ type: "DD_MM_YYYY", value: row.createdAt })} />
                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default ListUi