import { actions } from "../../utils/constants";


const initialState = {
    open: false,
    component: <></>,
    size:'md'

}
const modalReducer = (state = initialState, action) => {

    switch (action.type) {
        case actions.OPEN_MODAL: return { ...state, open: true, component: action.component,size: action.size };
        case actions.CLOSE_MODAL: return { ...state, ...initialState };
        default: return { ...state }
    }

}
export default modalReducer