import { Dialog, Slide } from '@mui/material';
import { forwardRef } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { closeModal } from '../store/actions/modalAction'

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

const PopUpModal = () => {

    const modal = useSelector(state => state.modal)
    const dispatch = useDispatch()

    return <Dialog
    
        fullWidth={true}
        TransitionComponent={Transition}
        maxWidth={modal.size}
        open={modal.open}
        onClose={() => { dispatch(closeModal()) }}
    >
        {modal.component}
    </Dialog>
}
export default PopUpModal