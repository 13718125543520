import { Badge, Chip, CircularProgress, Collapse, Grid, IconButton, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Tooltip, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useSelector } from "react-redux"

import { center } from "../../../assets/css/theme/common"
import SearchBar from "../../../components/inputs/SearchBar"
import { Download, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material"
import { Fragment, useState } from "react"
import { dateConverter } from "../../../utils/helper"
import GrayBgBox from "../../../components/layouts/GrayBgBox"
import SubmitButton from "../../../components/button/SubmitButton"
import { memo } from "react"

const Row = memo(({ row, onDownloadInvoice, ...props }) => {

    const [open, setOpen] = useState(false)
    const [downloading, setDownloading] = useState(false)

    const onDownloadBtnClick = async () => {        
        await onDownloadInvoice(row._id, row.invoiceId)

    }
    return <Fragment key={row.usercode}>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell >{dateConverter({ value: row.createdAt })}</TableCell>
            <TableCell >{row.invoiceId}</TableCell>
            <TableCell >
                {
                    row.devices && Array.isArray(row.devices) && row.devices.length
                }
            </TableCell>
            <TableCell >

                {
                    row.devices && Array.isArray(row.devices) && row.devices.reduce((total, num) => total + num.amount, 0)
                }
            </TableCell>
            <TableCell>
                {downloading ? <CircularProgress /> : <Tooltip title="download invoice">
                    <IconButton
                        aria-label="download"
                        size="small"

                    >
                        <Download onClick={onDownloadBtnClick} />
                    </IconButton>
                </Tooltip>}
            </TableCell>
            <TableCell >

                <IconButton
                    aria-label="expand row"
                    size="small"
                    onClick={() => setOpen(!open)}
                >
                    {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                </IconButton>


            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit sx={{ width: "100%" }}>
                    <GrayBgBox sx={{ width: "100%" ,flexWrap:"wrap"}}>
                        {
                            row.devices && Array.isArray(row.devices) && row.devices.map(device => <Chip key={device.boxId} color="primary" label={device.boxId} style={{marginRight:2,marginBottom:2}} ></Chip>)
                        }
                    </GrayBgBox>
                </Collapse>
            </TableCell >
        </TableRow>
    </Fragment>
})


const InvoicePageUi = ({ listLoading, filters, setFilters, list, onDownloadInvoice }) => {
    const user = useSelector(state => state.user)
    return (
        <>

            <Box pt={4} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Invoices</Typography>
                        <Box pl={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
                            <SearchBar placeholder={"Search by Invoice Number"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />
                        </Box>
                    </Box>
                    <TableContainer >
                        <Table aria-label="collapsible table">
                            <TableHead>
                                <TableRow>
                                    <TableCell >Date</TableCell>
                                    <TableCell>Invoice number</TableCell>
                                    <TableCell >Quantity</TableCell>
                                    <TableCell >Amount</TableCell>
                                    <TableCell >Download</TableCell>
                                    <TableCell ></TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={6} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    <Row key={row._id} row={row} onDownloadInvoice={onDownloadInvoice} />


                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default InvoicePageUi