
import { Delete, Publish, Remove } from "@mui/icons-material"
import { Box, Button, Checkbox, Chip, CircularProgress, DialogActions, DialogContent, DialogTitle, IconButton, Switch, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from "@mui/material"
import { memo, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { fetchProducts, fetchSoldTypes } from "../../../apis/inventory.api"
import { center } from "../../../assets/css/theme/common"

import SubmitButton from "../../../components/button/SubmitButton"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import CustomInput from "../../../components/inputs/CustomInput"
import { removeItemFromDeviceList } from "../../../store/actions/importDeviceSheetAction"

import { updateInventoryListAction } from "../../../store/actions/invenotryListAction"
import { closeModal } from "../../../store/actions/modalAction"
import { permissions } from "../../../utils/constants"

const ImportDeviceUi = ({ fields, setFields, onSubmit, onFileSelect, dispatchSheet, sheet, loading }) => {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>

        <DialogTitle variant="h3">
            Import Stock
            <Typography variant="h6" color="red">{fields.err} </Typography>
        </DialogTitle>

        <DialogContent sx={{ minHeight: '100px', display: "flex", position: "relative" }}>

            <Box sx={{ minHeight: '100px', width: "100%", display: "flex" }}>
                {!loading && sheet && sheet.length == 0 &&
                    <Button variant="outlined" component="label" fullWidth={true}>
                        <Publish fontSize="large" />

                        <Typography >Upload CSV</Typography>
                        <input hidden accept=".csv" onChange={onFileSelect} type="file" />
                    </Button>
                }
                {
                    loading && <Box sx={{ ...center, width: "100%" }}><CircularProgress /></Box>
                }


                {!loading && sheet && sheet.length > 0 && <>


                    <Box sx={{ border: 1, p: 1, borderRadius: 1, flexDirection: "column", overflowY: "scroll", flex: 3, display: 'flex', maxHeight: "100%" }}>
                        <Typography variant="h5" mb={2}>Sheet</Typography>
                        <TableContainer >


                            <Table stickyHeader aria-label="collapsible table">

                                <TableHead sx={{ zIndex: 1 }}>
                                    <TableRow sx={{ backgroundColor: 'red' }}>
                                        <TableCell></TableCell>
                                        <TableCell>Box Id</TableCell>
                                        <TableCell>Mac Id</TableCell>
                                        <TableCell>Converted Boxes</TableCell>
                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>

                                    {
                                        sheet.map((row) => <Row err={row.hasError} key={row.id} id={row.id} dispatchSheet={dispatchSheet} boxId={row.boxId} macId={row.macId} convertedBoxes={row.convertedBoxes} />)
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>


                    </Box>

                    <Box sx={{ border: 1, p: 1, borderRadius: 1, ml: 1, flexDirection: "column", overflowY: "scroll", flex: 1, display: 'flex', maxHeight: "100%" }}>
                        <Typography variant="h5" mb={2}>Details</Typography>



                        <Box sx={{}}><CustomInput type="number" placeholder="Unit Price" onChange={(e) => setFields({ ...fields, defaultAmount: e.target.value })} /></Box>
                        <Box sx={{}}><CustomInput type="text" placeholder="Invoice number" onChange={(e) => setFields({ ...fields, invoiceId: e.target.value })} /></Box>
                        {
                            <Box sx={{}}>
                                <AsyncDropDown


                                    label="Products"
                                    onChange={async (changedVal) => { setFields({ ...fields, err: '', product: changedVal }) }}
                                    titleKey={'name'}
                                    valueKey={"_id"}
                                    lazyFun={fetchProducts} />
                            </Box>}

                    </Box>
                </>}

            </Box>
        </DialogContent>
        <DialogActions>
            <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
            <SubmitButton disabled={sheet.length == 0} onClick={onSubmit ? onSubmit : handleClose} title="Import" ></SubmitButton>
        </DialogActions>


    </>
}


export default ImportDeviceUi

const Row = memo(({ dispatchSheet, err, id, boxId, macId, convertedBoxes }) => {
    console.log('soham')
    const onRemoveButtonClick = () => {
        dispatchSheet(removeItemFromDeviceList(id))
    }
    return <Tooltip title={err}>
         <TableRow sx={{ background: err != "" ? "pink" : "white" }}>
        <TableCell>{id}
        </TableCell>
        <TableCell>{boxId}</TableCell>
        <TableCell>{macId}</TableCell>
        <TableCell>{
            convertedBoxes.map((val) => <Chip key={val} label={val} />)
        }</TableCell>
        <TableCell><IconButton onClick={onRemoveButtonClick}><Remove /></IconButton></TableCell>
    </TableRow>
    </Tooltip>
})

