import { Button, Grid, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link, useParams } from "react-router-dom"
import { center } from "../../../assets/css/theme/common"
import SubmitButton from "../../../components/button/SubmitButton"
import SearchBar from "../../../components/inputs/SearchBar"
import StockByProductCategory from "../../../components/layouts/inventory/StockByProductCategory"
import { permissions } from "../../../utils/constants"
import { removeLastBracketFromName } from "../../../utils/helper"


const StockPageUi = ({ listLoading, filters, setFilters, list, onMassSellBtnClick, onFreeTransferBtnClick,onTransferBtnClick, onImportBtnClick, countLoading, counts, fetchList }) => {

    const user = useSelector(state => state.user)
    const params = useParams()
    return (
        <>
            {/* <Box p={4} pl={0} pr={0}>
                <StockByProductCategory loading={countLoading} data={counts} onClick={fetchList} activeId={filters.productCategory} />
            </Box> */}
            <Box p={4} pl={0} pr={0} >
                <Paper elevation={2} sx={{ width: "100%", padding: 4 }} >
                    <Box mb={4} sx={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography variant="h3" >Stock Items</Typography>
                        <Box pl={2} sx={{ display: "flex", alignItems: "flex-end", flexDirection: "column", flex: 1 }}>
                            <SearchBar placeholder={"Search by Box Id"} value={filters.search} onChange={(e) => { setFilters({ ...filters, search: e.target.value }) }} />


                        </Box>
                    </Box>

                    <Box sx={{ display: "flex", flex: "none", justifyContent: "space-between", mt: 2, mb: 2 }}>
                        <Box sx={{ display: 'flex' }}>
                            <Box pr={2}>  {user.permissions.includes(permissions.CRM_MANAGERS) && <SubmitButton title="Import" onClick={onImportBtnClick} sx={{ marginRight: 2 }} />}</Box>
                        </Box>
                        <Box sx={{ display: 'flex', minWidth: "50%", flex: "none", justifyContent: "flex-end" }}>
                            <Box sx={{ width: "50%" }} pr={2}>  {user.permissions.includes(permissions.TRANSFER_DEVICES) && <SubmitButton title="Channel Sale" onClick={() => { onTransferBtnClick() }} sx={{ marginRight: 2 }} />}</Box>
                            <Box sx={{ width: "50%" }}> <SubmitButton variant="outlined" title="Retail Sale" onClick={() => { onMassSellBtnClick() }} /></Box>
                        </Box>
                    </Box>

                    <TableContainer >


                        <Table aria-label="collapsible table">

                            <TableHead>
                                <TableRow>
                                    <TableCell>Box Id</TableCell>
                                    <TableCell sx={{ minWidth: "100px" }}>Mac Id</TableCell>
                                    <TableCell sx={{ minWidth: "140px" }}>Product Name</TableCell>
                                    <TableCell sx={{ minWidth: "140px" }}>Product Color</TableCell>
                                    <TableCell sx={{ minWidth: "140px" }}>Product Size</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {listLoading &&

                                    <>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>
                                        <TableRow ><TableCell colSpan={6}  ><Skeleton width={"100%"} height={'50px'} /></TableCell></TableRow>

                                    </>
                                }
                                {!listLoading && list && list.result && list.result.length <= 0 && <TableRow >
                                    <TableCell colSpan={6} align="Center" ><Typography sx={{ ...center }} variant="h3" >No Data</Typography> </TableCell>
                                </TableRow>}
                                {!listLoading && list && list.result && list.result.length > 0 && list.result.map((row, index) => (
                                    <TableRow key={row._id}>
                                        <TableCell > <Link to={`/dashboard/${params.usercode}/device/${row.boxId}`} > {row.boxId}</Link></TableCell>
                                        <TableCell>{row.macId}</TableCell>

                                        <TableCell >{row.product && removeLastBracketFromName(row.product.name)}</TableCell>
                                        <TableCell >{row.product.color && row.product.color.name}</TableCell>
                                        <TableCell >{row.product.type && row.product.type.name}</TableCell>

                                        <TableCell >
                                            {filters.status == 'sellable' ? <Box sx={{ display: "flex" }}>
                                                {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Box>
                                                    <SubmitButton sx={(theme) => ({  fontSize: theme.typography.body2 })} title={"Channel Sale"} onClick={() => { onTransferBtnClick([{ boxId: row.boxId, selected: true }], "sm") }} />
                                                </Box>}
                                                
                                                <Box sx={{mx: 3,}}>
                                                    <SubmitButton sx={(theme) => ({  fontSize: theme.typography.body2 })} title={"Retail sale"} onClick={() => { onMassSellBtnClick([{ boxId: row.boxId, selected: true }], "sm") }} />
                                                </Box>
                                                {user.permissions.includes(permissions.TRANSFER_DEVICES) && <Box>
                                                    <SubmitButton sx={(theme) => ({fontSize: theme.typography.body2 })} title={"Free Sale"} onClick={() => { onFreeTransferBtnClick([{ boxId: row.boxId, selected: true }], "sm") }} />
                                                </Box>}
                                            </Box> : "NA"}
                                        </TableCell>

                                    </TableRow>
                                ))}
                            </TableBody>



                        </Table>


                        {list && list.total > 0 && <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            component="div"
                            count={list.total}
                            rowsPerPage={parseInt(filters.pageSize)}
                            page={parseInt(filters.pageNo) - 1}
                            onPageChange={(e, page) => { setFilters({ ...filters, 'pageNo': page + 1 }) }}
                            onRowsPerPageChange={(e) => { setFilters({ ...filters, 'pageSize': e.target.value }) }}
                        />}
                    </TableContainer>
                </Paper>
            </Box>

        </>
    )
}
export default StockPageUi