import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import ListUi from "./ListUi"
import { getReplacementTicketListApi } from "../../apis/replacement.api"
import TicketCount from "./TicketCounts"

const ListController = ({ onlyme }) => {

    const [list, setList] = useState({})
    const [listLoading, setListLoading] = useState(false)


    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        pageNo: 1,
        pageSize: 10,
        search: '',
        onlyme,
        status: null,
        key:''

    })
    const fetchList = () => {

        setListLoading(true)
        dispatch(callApiAction(
            async () => await getReplacementTicketListApi({ ...filters,onlyme }),
            (response) => {

                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))
    }



    useEffect(() => {
        fetchList()
    }, [filters,onlyme])


    return (
        <>
            <TicketCount key={filters.key} onlyme={onlyme} onChangeStatus={(val) => setFilters({ ...filters, status: val })} />
            {filters.status && <ListUi

                listLoading={listLoading}
                filters={filters}
                setFilters={setFilters}
                list={list}
            />}

        </>
    )
}
export default ListController