import { DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material"
import { Box } from "@mui/system"
import { useDispatch } from 'react-redux'
import {  getLeadUsersApi } from "../../apis/lead.api"
import { getUserRolesApi } from "../../apis/user.api"
import SubmitButton from "../../components/button/SubmitButton"
import AsyncDropDown from "../../components/inputs/AsyncDropDown"
import CustomInput from "../../components/inputs/CustomInput"
import { closeModal } from "../../store/actions/modalAction"

const LeadForwardUI = ({ fields, setFields, loading, onSubmit }) => {
    const dispatch = useDispatch()
    const handleClose = () => {
        dispatch(closeModal())
    }
    return <>
        <Box component="form" sx={{ display: "flex", flexDirection: "column", overflowY: "auto" }} maxHeight="100%" onSubmit={onSubmit} >
            <DialogTitle variant="h3">{'Forward Lead'}
                <Box component={'div'} ><Typography variant="h4" color={'red'}>{fields.err} </Typography></Box>
            </DialogTitle>
            <DialogContent>

               


                <AsyncDropDown

                    lazyFun={getLeadUsersApi}
                    label="Assigned To*"
                    onChange={async (changedVal) => { setFields({ ...fields, err: '', to: changedVal }) }}
                    titleKey={'name'}
                    valueKey={"_id"}


                />

                <CustomInput
                    multiline
                    rows={4}
                    disabled={loading}
                    value={fields.message}
                    onChange={(e) => setFields({ ...fields, err: '', message: e.target.value })}
                    type="text"
                    label={"Message*"}

                />

               

            </DialogContent>
            <DialogActions   >
                <SubmitButton onClick={handleClose} title={'close'}></SubmitButton>
                <SubmitButton type="submit" title="Forward" ></SubmitButton>
            </DialogActions>
        </Box>
    </>
}
export default LeadForwardUI