import * as React from 'react';

import { styled } from '@mui/material/styles';
import { Button, CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

const SubmitButtonStyled = styled(Button)(({ theme }) => ({

    width: "100%",
    background: 'linear-gradient(93.3deg,#e6204b -67.4%,#a91674 180.21%)',
    color: theme.palette.light.main,
    minWidth: "195px"
}));

const SubmitButton = ({ title, loading, ...props }) => {
    return <SubmitButtonStyled {...props}>
        {props.icon}
        {!loading && title}

        {loading && <CircularProgress size={25} color="light" ml={4} />}

    </SubmitButtonStyled>
}

export default SubmitButton

