import { memo, useEffect, useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { callApiAction } from "../../../store/actions/commonAction"
import { useParams } from "react-router-dom"

import { fetchInventoryDetails, fetchProductCategoryApi, inventoryChannelSummaryApi, inventorySummaryApi } from "../../../apis/inventory.api"

import SummaryUI from "./SummaryUI"
import { openModal } from "../../../store/actions/modalAction"
import ReturnBoxesController from "../return/ReturnBoxesController"
import { soldCategoriesName } from "../../../utils/constants"
import { getDateFiltersTime } from "../../../utils/helper"
const SummaryController = ({ isChannel,isLifetime }) => {

    const [list, setList] = useState([])


    const [listLoading, setListLoading] = useState(false)
    const [inventorySummary, setInventorySummary] = useState([])

    const [productCategoryList, setproductCategoryList] = useState([])
    const [productCategoryListLoading, setproductCategoryListLoading] = useState(false)

    const { inventoryCount } = useSelector(state => state)
    const dispatch = useDispatch()
    const params = useParams()
    const [filters, setFilters] = useState({
        usercode: params.usercode,
        
        

    })

    const fetchList = () => {

        setListLoading(true)
        setproductCategoryListLoading(true)

        dispatch(callApiAction(
            async () => await fetchProductCategoryApi({ ...filters}),
            (response) => {
                setproductCategoryList(response.result)
                setproductCategoryListLoading(false)
            },
            (err) => {
                setproductCategoryListLoading(false)
            }
        ))

        dispatch(callApiAction(
            async () => isChannel ? await inventoryChannelSummaryApi({ ...filters }) : await inventorySummaryApi({ ...filters ,isLifetime,...getDateFiltersTime(inventoryCount.filters.dateTime), ...inventoryCount.filters}),
            (response) => {
                setList(response)
                setListLoading(false)
            },
            (err) => {
                setListLoading(false)
            }
        ))


    }


    useEffect(() => {
        if (filters.usercode != params.usercode)
            setFilters({ usercode: params.usercode })
    }, [params])

    useEffect(() => {
        fetchList()
    }, [filters,inventoryCount.filters])
   

    const setCountWithCategory = (arr, hideTotal) => {


        let temp = []
        let i = hideTotal ? 0 : 1
        if (!hideTotal)
            temp[0] = [{
                title: "Total Supply",
                redirect: ''
            }]

        for (let stockDetail of arr) {
            let j = 1
            if (stockDetail.title != soldCategoriesName.REPLACED) {


                temp[i] = [{
                    title: stockDetail.title == soldCategoriesName.RETURNED ? "Replacement Stock" : stockDetail.title,
                    redirect: (stockDetail.redirect ? stockDetail.redirect : '')
                }]

                for (let productCategory of productCategoryList) {

                    if (productCategory.name != "OTHER") {
                        let count = 0
                        if (stockDetail.productCategories && Array.isArray(stockDetail.productCategories)) {
                            for (let stockCategory of stockDetail.productCategories) {
                                if (stockCategory.productCategoryId == productCategory._id) {
                                    count = stockCategory.productCategoryCount
                                    break;
                                }
                            }
                        }
                        temp[i][j] = count
                        if (stockDetail.totalSupply && !hideTotal && stockDetail.title != soldCategoriesName.RETURNED) {

                            temp[0][j] = !temp[0][j] ? count : (temp[0][j] + count)
                        }
                        j++
                    }
                }

                temp[i][productCategoryList.length + 1] = temp[i].reduce((prev, curr) => (typeof (curr) != "object") ? (prev + parseInt(curr)) : prev, 0)
            }
            i++;

        }

        if (!hideTotal)
            temp[0][(productCategoryList.length + 1)] = temp[0].reduce((prev, curr) => (typeof (curr) != "object") ? (prev + parseInt(curr)) : prev, 0)

        return temp

    }
    useEffect(() => {
        if (!listLoading && !productCategoryListLoading && productCategoryList.length > 0 && (list).length > 0) {

            if (isChannel)
                setInventorySummary(

                    list.map((listItem) => ({
                        title: listItem.name,
                        subTitle: listItem.description,
                        dropdown:listItem.dropdown,
                        data: setCountWithCategory(listItem.data)
                    }))


                )
            else
                setInventorySummary(

                    // setCountWithCategory(list[0].data, true),
                    // setCountWithCategory(list[1].data, true),
                    // setCountWithCategory(list[2].data, true),
                    // setCountWithCategory(list[1].data, true)

                    list.map((listItem) => ({
                        title: listItem.name,
                        subTitle: listItem.description,
                        dropdown:listItem.dropdown,
                        data: setCountWithCategory(listItem.data, true)
                    }))

                )

        }
    }, [productCategoryList, list])

    const onReturnButtonClick = () => {
        dispatch(openModal(<ReturnBoxesController callBack={fetchList} />, "sm"))
    }
    return (
        <>
            <SummaryUI
                callBack={fetchList}
                listLoading={listLoading}
                list={inventorySummary}
                onReturnButtonClick={onReturnButtonClick}
                headers={productCategoryList}
            />

        </>
    )
}
export default memo(SummaryController)