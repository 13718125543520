import { useState } from "react"
import CustomInput from "../../../components/inputs/CustomInput"
import { Box } from "@mui/material"
import TicketDetailsController from "../TicketDetailsController"
import SubmitButton from "../../../components/button/SubmitButton"
import { REPLACEMENT_TICKETS_STATUS, REPLACEMENT_TICKETS_STATUS_OBJ } from "../../../utils/constants"
import AsyncDropDown from "../../../components/inputs/AsyncDropDown"
import { fetchInventoryList } from "../../../apis/inventory.api"

const CustomerReplaceDeviceUI = ({ loading, fields, setFields,   replaceDevice, ticketData }) => {


    return <>
        { <Box component="form" onSubmit={replaceDevice} >
            { }
            <Box mt={2} mb={2}>
            <AsyncDropDown
                    label="Select Devices To Be Replaced*"
                    multiple
                    InputComponent={(props)=><CustomInput placeholder label="Select Devices To Be Replaced" {...props} />}
                    onChange={(devices) => { setFields({ ...fields, devices:devices?devices.map((item)=>item.boxId):[] }) }}
                    titleKey={'boxId'}
                    valueKey={"boxId"}
                    lazyFun={async (filters) => { return await fetchInventoryList({ ...filters,  onlyBoxId: true,status:"sellable" }) }}

                />
            </Box>
            <SubmitButton type="submit" title="Replace Device" loading={loading}  />
        </Box>}

    </>
}
export default CustomerReplaceDeviceUI